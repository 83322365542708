.aboutPage-container {
  padding: 2rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aboutPage-container a {
  text-decoration: none;
}

.aboutPage-container h2,
.aboutPage-container p,
.aboutPage-container .contact-icons {
  padding-bottom: 3rem;
  margin: 0;
}

.resume-button-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.resume-button-container p {
  padding-bottom: 0;
}

.aboutPage-container .contact-icons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;
  margin-top: 2rem;
  @media (max-width: 768px) {
    width: 100%;
  }
}

.aboutPage-container .social-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: width 0.9s ease-in-out;
  transition: border-radius 0.03s ease-in-out;
}

.aboutPage-container .social-icon-container:hover {
  width: 200px;
  border-radius: 20px;
}

.aboutPage-container .icon {
  font-size: 24px;
  color: #ddd;
  transition: color 0.2s ease-in-out;
}

.aboutPage-container .social-icon-container:hover .icon {
  color: #000; /* Change icon color on hover */
}

.aboutPage-container .icon-text {
  display: none; /* Initially hide the text */
  margin-left: 8px;
  color: #ddd;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.aboutPage-container
  .social-icon-container:hover
  .icon-text {
  display: block; /* Show text on hover */
  opacity: 1;
}

.aboutPage-container .contact-icons a[href^="https://github.com"] .social-icon-container:hover,
.aboutPage-container .contact-icons a[href^="https://github.com"] .social-icon-container:hover .icon-text
{
  background-color: #ddd;
  color: #222;
}

.contact-icons .social-icon-container:hover .icon-text {
  color: #000;
}

.horizontal-line {
  height: 2px;
  color: #222;
  background-color: #222;
  width: 100%;
  opacity: 0.5;
}
