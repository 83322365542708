.footer {
  background-color: #333;
  color: #fff;
  padding: 0.5rem 2rem;
  bottom: 0;
  position: relative;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
}

.footer p {
  margin-bottom: 0;
}

.footer p,
.contact-info a {
  font-weight: 400;
  font-size: 14px;
  color: #b8b8b8;
  letter-spacing: 0.5px;
  text-align: center;
}

.footer .contact-info h2 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.footer .contact-info a {
  line-height: 1.5em;
  letter-spacing: 0.5px;
  margin: 0;
  text-decoration: none;
}

.footer .contact-info img {
  width: 14.8px;
  margin-right: 3.5px;
  height: auto;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
}

.contact-info {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
    align-items: center;
  }
}

.footer-container .contact-icons {
  display: flex;
  align-items: flex-start;
  column-gap: 0.5rem;
  justify-content: space-between;
  @media (max-width: 768px) {
    margin-bottom: 0.5rem;
  }
}

.footer-container .contact-icons a {
  display: flex;
  text-decoration: none;
  color: #333;
}

.footer-container .contact-icons a .social-icon-container {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-container .contact-icons a svg {
  font-size: 1.2rem;
  color: transparent;
}

.footer-container .contact-icons a svg path {
  fill: #fff;
}

.footer-container .contact-icons a:last-child {
  margin-bottom: 0;
}

.footer-container .contact-icons div {
  margin-top: 10px;
  font-size: 1rem;
}

.footer-container .icon-text {
  display: none;
}
