/* Hero section styles */
.hero {
  background-color: #f9f9f9;
  padding: 50px 0;
  text-align: center;
  display: flex;
  width: 100%;
  @media (max-width: 768px) {
    justify-content: center;
  }
}

.hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
}

.hero p {
  font-size: 1.2rem;
}

.text-animation,
.image-container {
  width: 50%;
}

.image-container {
  @media (max-width: 768px) {
    display: none;
  }
}

.text-animation {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.txt-rotate > .wrap {
  font-size: 50px;
  font-weight: 500;
  letter-spacing: 0.8px;
  line-height: 1.3;
}

.txt-rotate > .wrap::after {
  content: "";
  width: 5px;
  height: 60px;
  margin-left: 2px;
  background-color: #333;
  display: inline-block;
  animation: cursor-blink 1.5s steps(2) infinite;
}

@keyframes cursor-blink {
  0% {
    opacity: 0;
  }
}

.portfolioImage {
  width: 50%;
  border-radius: 20px;
  pointer-events: none;
}
