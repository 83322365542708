.projects-container {
  padding: 2rem 5rem;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.projects-container h2,
.projects-container p {
  padding-bottom: 2rem;
}

.projects-container img {
  width: 248px;
  height: auto;
  border-radius: 10px;
}

.project-item-details {
  border-radius: 0 0 10px 10px;
}
