.about {
  padding: 3.5rem 10rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: padding 0.5s ease;
  @media (max-width: 768px) {
    padding: 3.5rem 2.5rem;
  }
}

.about h2 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.heart {
  width: 1.5%;
  @media (max-width: 768px) {
    width: 4%;
  }
}
