.contact-icons a[href^="https://www.instagram.com"] .social-icon-container
{
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
}

.contact-icons a[href^="https://www.linkedin.com"] .social-icon-container
{
  background-color: #0a66c2;
}

.contact-icons a[href^="https://github.com"] .social-icon-container
{
  background-color: #222;
}

.contact-icons a[href^="https://www.facebook.com/"] .social-icon-container
{
  background-color: #1877f2;
}
