.layout {
  margin: 0;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.layout-page {
  flex: 1;
}
