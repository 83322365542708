.projects {
  margin-top: 20px;
  text-align: center;
  text-decoration: none;
}

.project-card {
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  margin: 0 10px;
  text-align: center;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-decoration: none;
  color: #333;
}

.carousel-caption a {
  text-decoration: none;
  color: #333;
}

.carousel-caption a:hover {
  text-decoration: underline;
}

.project-card h3 {
  margin-top: 20px;
  font-size: 1.5rem;
  color: #333;
  text-decoration: none;
}

p {
  margin-top: 10px;
  font-size: 1rem;
  color: #666;
}

.carousel {
  width: 80%;
  margin: 0 auto;
}

.carousel-item {
  background: transparent;
}

.carousel-item img {
  width: 100%;
  height: 500px; /* Adjust height for consistency */
  object-fit: contain;
  background-color: transparent;
  border-radius: 10px;
}
